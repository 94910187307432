import React from 'react'
import CustomInput from './CustomInput'

const AddressFields = () => {
  return (
    <div>
    <CustomInput
      width={" w-[295px] sm:w-[774px] "}
      type="text"
      name="address_name"
      placeholder="Address Name"
    />
    <CustomInput
      width={" w-[295px] sm:w-[774px] "}
      type="number"
      name="number"
      placeholder="Phone Number"
    />
    <CustomInput
      width={" w-[295px] sm:w-[774px] "}
      type="text"
      name="Country"
      placeholder="Country"
    />
    <CustomInput
      width={" w-[295px] sm:w-[774px] "}
      name="email"
      type="text"
      placeholder="Governorate/Area"
    />

    <div className="flex flex-row justify-between items-center min-w-0 ">
      <div className="flex flex-col min-w-0">
        <CustomInput
          width={" w-[76px] "}
          height={`h-14 `}
          name="Block"
          type="text"
          placeholder="Block"
        />
        <CustomInput
          width={" w-[76px] "}
          height={`h-14 `}
          name="Building"
          type="text"
          placeholder="Building"
        />
      </div>
      <div className="flex flex-col min-w-0">
        <CustomInput
          width={" w-[76px] "}
          height={`h-14 `}
          name="Street"
          type="text"
          placeholder="Street"
        />
        <CustomInput
          width={" w-[76px] "}
          height={`h-14 `}
          name="Avenue"
          type="text"
          placeholder="Avenue"
          className={`outline-none text-white border-opacity-10 bg-transparent border-b-[1px] text border-b-white100 focus:border-b-gold h-14 w-[76px] `}
        />
      </div>
    </div>
  </div>
  )
}

export default AddressFields