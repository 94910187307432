import { Spinner } from "@material-tailwind/react";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { eye } from "../assets/icons";
import CustomInput from "../components/CustomInput";
import { fetchMyCart } from "../store/cartSlice";
import { loginUser } from "../store/user/userSlice";
import appleLogo from "../assets/icons/apple.png";
import facebookLogo from "../assets/icons/facebook.png";
import googleLogo from "../assets/icons/google.png";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("Password is required"),
});

const SignIn = () => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const Naviagte = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSignup = () => {
    Naviagte("/signup");
  };

  const login = (values) => {
    dispatch(loginUser({ user_name: values.email, password: values.password }));
    dispatch(fetchMyCart({}));
    Naviagte("/");

    // // Create a cookie string with Secure and HttpOnly flags

    // const responseCart = await axiosInstance.post('/gmt/fetch_my_cart_v2', {}, {withCredentials: true});
    // // const responseCart = await axiosInstance.post('/gmt/fetch_my_cart_v2', {}, {withCredentials: true, setCookie: `session_id=${response.data.result.session_id}`});

    // const profile = await axiosInstance.post('/gmt/get_orders', {is_received: false}, {withCredentials: true,})
  };

  const handleSubmit = async (values) => {
    try {
      const response = await dispatch(
        loginUser({ user_name: values.email, password: values.password })
      ).unwrap();
      // You can now check the response here
      if (!response.code) {
        Naviagte("/");
        setServerError("");
      }
      setServerError("Invalid username or password");
    } catch (err) {
      console.error("Failed to log in: ", err);
    }
  };
  return (
    <div>
      <div
        className={` h-screen sm:h-auto flex flex-col justify-center items-center bg-background800 no-scrollbar pt-[50px]  sm:pt-[158px] sm:pb-48`}
      >
        <div
          className={` flex flex-col justify-center items-start sm:items-center w-auto h-auto sm:w-[610px] sm:h-[509px] bg-transparent sm:bg-gradient-to-b from-[#201D1D] via-[#201D1D] to-[#181515] rounded-lg`}
        >
          <span
            className={`text-2xl sm:text-5xl pt-0 mb-2 sm:pt-7	text-white title`}
          >
            SIGN IN
          </span>
          <div className={`flex flex-col `}>
            <Formik
              initialValues={{ email: "", password: "" }}
              // validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    className={`flex flex-col mb-[22px] text border-b-white100 focus:border-b-gold`}
                  >
                    <label
                      htmlFor="username"
                      className={`text-white100 opacity-50 text-xs font-normal pb-1`}
                    >
                      Username or email
                    </label>
                    {/* <br /> */}
                    <CustomInput
                      width={"w-full sm:w-[533px]"}
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      // className={`outline-none text-white bg-transparent w-[295px] sm:w-[533px]`}
                    />
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className={`text-white100 opacity-50 text-xs font-normal pb-1`}
                    >
                      Password
                    </label>

                    <div className="flex justify-end items-center relative  ">
                      <CustomInput
                        width={`w-[295px] sm:w-[533px]`}
                        // className={`outline-none text-white bg-transparent  w-[295px] sm:w-[533px]`}
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <img
                        src={isPasswordVisible ? eye : eye}
                        className="absolute mr-2 w-8 cursor-pointer"
                        alt="Toggle Password Visibility"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.password && touched.password && errors.password}
                    </span>
                    <span className={`text-gold text-xs mt-1`}>
                      {" "}
                      {serverError}
                    </span>
                  </div>
                  <div
                    className={`flex flex-row justify-between items-center pt-10`}
                  >
                    <div className={`flex justify-center`}>
                      <span className={`text-gold text-sm `}>
                        Forgot Password?
                      </span>
                    </div>
                    <div className={`flex justify-end`}>
                      <button
                        className="flex flex-row justify-center items-center px-4 py-2 rounded-full cursor-pointer bg-background900 text-tiny text-gold w-[150px] h-[56px] "
                        type="submit"
                        text={"Complete Registration"}
                        width={"w-full"}
                        height={"h-[56px] md:h-[56px]"}
                      >
                        {userDetail.status === "loading" ? (
                          <Spinner className="h-4 w-4" />
                        ) : (
                          "Sign in"
                        )}
                      </button>

                      {/* <Button
                      type="submit"
                      text={"Sign in"}
                      isBorder={false}
                      onClick={() => console.log("Download App")}
                      width={"w-[150px] md:w-[150px]"}
                      height={"h-[56px] md:h-[56px]"}
                      iconRight={RightArrow}
                    /> */}
                    </div>
                  </div>
                  <div className="flex gap-4 items-center justify-center pt-6">
                    <a href="https://apps.apple.com/in/app/gmt24/id1491746687">
                      <div
                        className={` rounded-xl  cursor-pointer bg-background900 bg-opacity-25 p-4 object-cover `}
                      >
                        <img className=" " src={appleLogo}></img>
                      </div>
                    </a>
                    <div
                      className={` rounded-xl  cursor-pointer bg-background900 bg-opacity-25 p-4  `}
                    >
                      <img className=" " src={facebookLogo}></img>
                    </div>{" "}
                    <div
                      className={` rounded-xl  cursor-pointer bg-background900 bg-opacity-25 p-4  `}
                    >
                      <img className=" " src={googleLogo}></img>
                    </div>{" "}
                  </div>
                  <div className={`flex justify-center items-center pt-9`}>
                    <span className={`text-base text-white `}>
                      Don’t Have an account?{" "}
                    </span>
                    <span
                      className={`text-gold cursor-pointer`}
                      onClick={handleSignup}
                    >
                      {" "}
                      Sign up!
                    </span>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
