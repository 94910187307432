import React from "react";
import WatchCard from "../components/WatchCard";
import { NewWatches } from "../constants/WatchData";
import CategoryBanner from "../components/CategoryBanner";
import Search from "../assets/icons/search.svg";
import Close from "../assets/icons/close.svg";
import RightIcon from "../assets/icons/Arrow-right.svg";
import Button from "../components/Button";
import CategoryFilters from "../components/CategoryFilters";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import StoreCard from "../components/StoreCard";

// import Close_svg from "../assets/icons/Close.svg"
function Category() {
  const navigation = useNavigate();
  const handleViewAll = () => {
    navigation("/Category");
  };
  return (
    <div className={`bg-background800 no-scrollbar`}>
      <CategoryBanner />
      <div
        className={`flex flex-col sm:flex-row gap-4 pl-4 sm:pl-[42px] py-[43px]`}
      >
        <div
          className={` sm:hidden flex w-[343px] h-[100%] p-2 rounded-lg  bg-background900 cursor-pointer justify-between items-center grow-[4]`}
        >
          <input
            className={`bg-transparent focus:outline-none text`}
            placeholder="Search"
          />
          <img src={Search} alt="Profile" className="w-[24px] h-[24px]" />
        </div>
        <div
          className={`flex flex-row justify-between items-center sm:flex-col   pr-[19px]`}
        >
          {/* <div
            className={`flex flex-row justify-center items-center sm:flex-col sm:items-start `}
          >
            <div className={` flex flex-row `}>
              <span className={`text-lg sm:text-5xl text-white`}>
                Rolex <span className="text-sm"> Luxury Watches</span>
              </span>
            </div>
          </div> */}
          <div className=" sm:hidden flex  items-end ">
            <span className={`text-base text-gold cursor-pointer `}>
              Clear Filter
            </span>
          </div>
        </div>
      </div>

      <div
        className={` flex flex-row justify-between pl-4 sm:pl-[42px] py-[43px]`}
      >
        <div>
          <span className={`text-lg sm:text-5xl text-white`}>
            Rolex <span className="text-sm"> Luxury Watches</span>
          </span>
        </div>
        <div>
          <Button
            text={"View All"}
            isBorder={false}
            background={"bg-none"}
            iconRight={RightIcon}
            onClick={handleViewAll}
            width={"w-[145px] md:w-[145px]"}
            height={"h-[64px] md:h-[56px]"}
          />
        </div>
      </div>
      <div
        // className={` grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center px-9 py-6  h-screen overflow-x-scroll  relative  no-scrollbar`}
        className={` grid  overflow-x-scroll no-scrollbar grid-cols-none grid-row grid-flow-col gap-4 sm:px-8 sm:py-6 sm:gap-9 `}
      >
        {NewWatches.map((value, index) => {
          return (
            // <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 `}>
            <>
              <div
                key={index}
                // className={`w-1/2 justify-start lg:w-1/4 xl:w-1/5 p-0`}
              >
                <StoreCard
                  watchDetail={value}
                  onClick={() => navigation("/product-detail")}
                />
              </div>
            </>
            // </div>
          );
        })}
      </div>
      <div
        className={` flex flex-row justify-between pl-4 sm:pl-[42px] py-[43px]`}
      >
        <div>
          <span className={`text-lg sm:text-5xl text-white`}>
            Rolex <span className="text-sm"> Luxury Watches</span>
          </span>
        </div>
        <div>
          <Button
            text={"View All"}
            isBorder={false}
            background={"bg-none"}
            iconRight={RightIcon}
            onClick={handleViewAll}
            width={"w-[145px] md:w-[145px]"}
            height={"h-[64px] md:h-[56px]"}
          />
        </div>
      </div>
      <div
        className={`  grid  overflow-x-scroll no-scrollbar grid-cols-none grid-row grid-flow-col gap-4 sm:px-8 sm:py-6 sm:gap-9 `}
      >
        {NewWatches.map((value, index) => {
          return (
            // <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 `}>
            <>
              <div
                key={index}
                // className={`w-1/2 justify-start lg:w-1/4 xl:w-1/5 p-0`}
              >
                <StoreCard
                  watchDetail={value}
                  onClick={() => navigation("/product-detail")}
                />
              </div>
            </>
            // </div>
          );
        })}
      </div>
      <div className={`hidden sm:flex sm:justify-center pt-[52px] pb-20`}>
        <Button
          text={"View More"}
          isBorder={false}
          onClick={() => console.log("Download App")}
          width={"w-[161px] md:w-[161px]"}
          height={"h-[40px] md:h-[40px]"}
        />
      </div>
    </div>
  );
}

export default Category;
