import { useEffect, useRef, useState } from "react";
import carousel_img from "../../src/assets/images/carousel-img.png";

const images = [carousel_img, carousel_img, carousel_img];
const delay = 3000;

const Slideshow = () => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // Automatically move to the next slide after the delay
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => resetTimeout();
  }, [index]);

  // Function to handle dot clicks
  const handleDotClick = (idx) => {
    resetTimeout(); // Reset the timer on manual navigation
    setIndex(idx);
  };

  return (
    <div className="max-w-[500px] mx-auto overflow-hidden flex flex-col justify-center gap-7">
      {/* Image Slider */}
      <div
        className="whitespace-nowrap transition-transform ease-in-out duration-1000"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {images.map((imgSrc, idx) => (
          <div
            key={idx}
            className="inline-block w-full h-[560px] rounded-[40px] overflow-hidden"
          >
            <img
              src={imgSrc}
              alt={`Slide ${idx + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="text-center hidden sm:block">
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`inline-block h-3 w-3 rounded-full cursor-pointer mx-[7px] mt-[15px] ${
              index === idx ? "bg-gold" : "bg-black"
            }`}
            onClick={() => handleDotClick(idx)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
