import React from "react";
import CustomInput from "../components/CustomInput";
import Button from "../components/Button";
import LocationIcon from "../assets/icons/location_arrow.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import Footer from "../components/Footer";
function ContactUs() {
  return (
    <>
    <div className="sm:w-full sm:h-auto pt-[54px] pb-11 flex flex-col px-9 sm:flex-row justify-between items-center bg-background800  gap-6">
      <div className="w-auto sm:w-1/2 flex justify-start">
        <div className="flex flex-col justify-start items-start">
          <span className="text-2xl sm:text-4xl text-haeder pl-2 sm:pl-4">CONTACT US</span>
          <div className="sm:p-8">
            <CustomInput
              width={" w-[295px] sm:w-[604px] "}
              type="text"
              name="name"
              placeholder="Your Name"
            />
            <CustomInput
              width={" w-[295px] sm:w-[604px] "}
              type="number"
              name="phone"
              placeholder="Phone Number"
            />
            <CustomInput
              width={" w-[295px] sm:w-[604px] "}
              type="email"
              name="email"
              placeholder="Email"
            />
            <div className="relative">
              <textarea
                rows={7}
                className="w-full resize-none bg-black/40 text-white outline-none rounded-lg mt-4 placeholder:p-4"
                placeholder="Type your Question..."
              ></textarea>
              <div className="absolute bottom-0 right-0 pr-[27px] pb-5">
                <Button
                  type="submit"
                  background={true}
                  text={"Send"}
                  isBorder={false}
                  onClick={() => console.log("Download App")}
                  width={"w-full md:w-[210px]"}
                  height={"h-[56px] md:h-[56px]"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-auto sm:w-2/2 flex justify-start items-start">
        <div className="flex flex-col justify-start items-start">
          <div className="flex flex-col gap-4">
            <span className=" din-text text-2xl text-haeder">Our Location</span>
            <div className="flex flex-row justify-start items-start gap-[13px]">

              <img src={LocationIcon} alt="Location icon" className=""/>
              <span className="text-white text-base">
                Jibla , Salhiya street<br/> Mabanee1 building , Floor1<br/> Kuwait city<br/>
                Kuwait
              </span>
            </div>
            <div className="flex flex-row gap-[13px]">
            <img src={PhoneIcon} className=""/>
            <span className="text-white text-base">+965 99889988</span>
              
              </div>
          </div>
          <div className="mapouter">
            <div className="gmap_canvas ">
              <iframe
                className="rounded-lg w-full h-full sm:h-[317px] sm:w-[633px] mt-[30px] "
                src="https://maps.google.com/maps?q=Burj%20Al%20Tujjar&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                // height="327" width="643"
                // frameborder="0"
                // scrolling="no"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default ContactUs;
