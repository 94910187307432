import React from "react";
import { Input, Radio, Checkbox } from "@material-tailwind/react";
import CartItems from "../components/CartItems";
import Button from "../components/Button";
import { CardData } from "../constants/CartData";
import arrowRight from "../assets/icons/Arrow-right.svg";
import Test from "../assets/images/category.png";
import { useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import AddressFields from "../components/AddressFields";
import { useDispatch, useSelector } from "react-redux";
const CheckoutCart = () => {
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);
  const cartDetail = useSelector((state) => state?.cart?.cartDetail);

  return (
    <div className="bg-background sm:bg-none">
      <div className="flex flex-col sm:flex-row justify-center px-0 sm:px-8 gap-11 rounded-sm bg-backgroundCart sm:bg-background800">
        <div className=" hidden sm:block w-3/5 pt-[54px]">
          <span className={`text-4xl title text-white `}>CHECKOUT</span>
          <div className={`p-8 bg-backgroundButton rounded-2xl`}>
            <span className={`text-2xl din-text text-white`}>
              Personal Info
            </span>
            <div>
              <CustomInput
                width={" w-[295px] sm:w-[774px] "}
                type="name"
                name="name"
                placeholder="Email"
                // className={`outline-none text-white border-opacity-10 bg-transparent border-b-[1px] text border-b-white100 focus:border-b-gold h-14  w-[295px] sm:w-[774px]`}
              />
            </div>
            {!userDetail?.name && (
              <div className="mt-4">
                <span className={`text-base text-white `}>Got an Account?</span>
                <span
                  className={`text-base text-gold cursor-pointer`}
                  onClick={() => navigation("/signin")}
                >
                  {" "}
                  Sign in
                </span>
              </div>
            )}
          </div>
          <div className={`p-8 mt-[26px] bg-backgroundButton rounded-2xl`}>
            <span className={`text-2xl din-text text-white`}>
              Shipping Address
            </span>
            <AddressFields />
          </div>

          <div className="mt-14 p-8  bg-backgroundButton rounded-2xl">
            <span className="text-2xl font-normal din-text text-white ">
              Payment Method
            </span>
            <div className="flex flex-col gap-0">
              <Radio name="type" color="yellow" label="KNET" defaultChecked />
              <Radio name="type" color="yellow" label="VISA/MASTER CARD" />
            </div>
          </div>
        </div>
        <div className="w-auto sm:w-2/5 h-[680px] mt-[51px] rounded-lg bg-background sm:bg-footer">
          <div className=" flex flex-col gap-8 p-4 sm:p-8  bg-backgroundCart sm:bg-footer">
            <span
              className={`hidden sm:block text-4xl font-din-text text-white`}
            >
              Review your Items
            </span>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col sm:hidden ">
                <span className="title text-base text-white100 font-normal">
                  Shipping address
                </span>
                <span className="text-din-text text-sm text-white100 font-normal">
                  Kuwait, Jabriya Block 9<br />
                  Street 8{" "}
                </span>
              </div>
              <div className="flex sm:hidden gap-2">
                <span className="text-din-text text-sm text-gold"> Change</span>
                <img src={arrowRight} />
              </div>
            </div>
            <hr className=" block sm:hidden" />
            <div className=" flex flex-col gap-3">
              {cartDetail?.cartItems?.map((data, index) => (
                <CartItems key={index} width="auto" data={data} />
              ))}
            </div>
            <div className="flex flex-col">
              {" "}
              <span className="text-xs text-white100"> Promo Code </span>
              <CustomInput
                width={" w-full"}
                height={`h-14 `}
                name="Promocode"
                type="text"
                placeholder="Promocode"
                // className={`outline-none border-opacity-10 text-white bg-transparent border-b-[1px] text border-b-white100 focus:border-b-gold h-14 w-auto`}
              />
              {/* <Input
                className="text-base text-din-text "
                variant="static"
                type="text"
                placeholder="Promocode"
              /> */}
            </div>
            <div className="flex flex-col sm:hidden">
              <span className="text-base title font-medium din-text text-white">
                PAYMENT METHOD
              </span>
              <div className="flex flex-col gap-0 text-din-text text-base">
                <Radio name="type" color="yellow" label="KNET" checked={true} />
                <Radio name="type" color="yellow" label="VISA/MASTER CARD" />
              </div>
            </div>
            <div className="flex justify-center items-center text-center">
              <span className="text-white100 text-base text-din-text">
                You will be redirected to the Payment <br />
                Gateway to complete the purchase
              </span>
            </div>
            <div className="flex flex-row  justify-center items-center text-center ">
              <Checkbox className={`flex `} color={"yellow"} />
              {/* label={ */}
              <div className="text-base text-din-text  ">
                <span className="text-whiteSecondary font-normal">
                  I agree to the
                </span>{" "}
                <span className="text-gold"> terms & conditions</span>
              </div>
            </div>
            <div>
              <hr />
              <div className="flex flex-row justify-between pt-4">
                <div className="flex flex-col text-white100 ">
                  {cartDetail?.isDelivery && (
                    <span className="text-base text-din-text">Sub total</span>
                  )}
                  <span className="text-base text-din-text">Shopping</span>
                  <span className="text-lg text-title pt-[14px]">TOTAL</span>
                </div>
                <div className="flex flex-col text-white100 ">
                  <span className="text-base text-din-text">
                    KD {cartDetail?.totalPrice}
                  </span>
                  {cartDetail?.isDelivery && (
                    <span className="text-base text-din-text">
                      KD {cartDetail?.charges}
                    </span>
                  )}
                  {
                    <span className="text-lg text-title pt-[14px]">
                      {cartDetail?.totalPrice}
                    </span>
                  }
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <Button
                type="submit"
                background={true}
                text={`Pay Now (KD ${cartDetail?.totalPrice})`}
                isBorder={false}
                onClick={() => navigation("/checkout-success")}
                width={"w-[339px] md:w-[430px]"}
                height={"h-[56px] md:h-[56px]"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCart;
