import React from "react";
import { gmt24Logo } from "../assets/images";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigation = useNavigate();

  return (
    <div className={`bg-footer pb-[140px] `}>
      <div className={`flex flex-col sm:flex-row justify-center items-center sm:justify-start sm:items-start gap-[48px] sm:gap-[176px] pt-4 sm:pt-12 sm:pl-8 sm:pr-16`}>
        <div className={`flex flex-col justify-center items-center sm:items-start gap-8`}>
          <img
            src={gmt24Logo}
            alt="hoobank"
            className="w-[101px] h-[36px] cursor-pointer"
          />
          <span className={`text-small hidden sm:block`}>
            © 2020 - All rights
            <br /> reserved
          </span>
          <span className={`text-small block sm:hidden`}>
            © 2020 - All rights reserved
          </span>
        </div>
        <div>
          <div className={`flex flex-col justify-center items-center sm:items-start `}>
            <span className={`title-medium text-haeder mb-3`}>Categories</span>
            <ul className={`text-tiny text-haeder text-center sm:text-left flex flex-col gap-5 `}>
              <li className="cursor-pointer" onClick={()=>navigation('/watch')}>Watch</li>
              <li className="cursor-pointer" onClick={()=>navigation('/accessory')} >Accessoies</li>
            </ul>
          </div>
        </div>
        <div>
          {" "}
          <div className={`flex flex-col  justify-center items-center sm:items-start`}>
            <span className={`title-medium text-haeder mb-3`}>Legal</span>
            <ul className={`text-tiny text-haeder text-center sm:text-left flex flex-col gap-5`}>
              <li className="cursor-pointer" onClick={()=>navigation('/legal-notice')}>Legal Notice</li>
              <li className="cursor-pointer" onClick={()=>navigation('/privacy-policy')}>Data Privacy Policy</li>
              <li className="cursor-pointer">FAQ / Help</li>
            </ul>
          </div>
        </div>
        <div>
          {" "}
          <div className={`flex flex-col  justify-center items-center sm:items-start`}>
            <span className={`title-medium text-haeder mb-3`}>Company</span>
            <ul className={`text-tiny text-haeder text-center sm:text-left flex flex-col gap-5 `}>
            <li className="cursor-pointer" onClick={()=> navigation('/contact-us')}>Contact us</li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
