import React from "react";
import Chip from "./Chip";
import { useNavigate } from "react-router-dom";

const StoreCard = ({
  watchDetail,
  imgURL,
  brand,
  model,
  strap,
  price,
  currency,
  tags,
  onClick,
}) => {
  const navigation = useNavigate();

  return (
    <div
      className={`  w-[188px] max-h-[300px] mb-0 sm:mb-9 sm:w-[300px] sm:max-h-[465px] rounded-xl sm:rounded-[10px] cursor-pointer border border-[#000000]/[.30]`}
      style={{
        // borderRadius: '10px',
        background: "linear-gradient(136deg, #201D1D 50.15%, #181515 100%))",
        // boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
      }}
      onClick={() => navigation("/product-detail")}
    >
      <div className="flex justify-center sm:justify-start sm:items-start sm:basis-0 items-center basis-2/3">
        <img
          src={watchDetail?.images && watchDetail?.images[0]?.src}
          alt="hoobank"
          className="w-[156px] h-[141px] sm:w-[318px] sm:h-[286px] object-cover rounded-none sm:rounded-t-[10px]"
        />
      </div>
      <div
        className={`flex flex-col  items-start p-4 gap-2 sm:basis-0 basis-1/3`}
      >
        <div className={`flex flex-col gap-[3px] `}>
          <span
            className={`text-xs sm:text-tiny din-text  sm:text-base text-white opacity-60`}
          >
            {watchDetail?.product_brand_id?.name}
          </span>
          <span
            // className={`text-lg din-text font-normal uppercase sm:title-medium text-haeder`}
            className={` text-haeder title text-lg sm:text-2xl `}
          >
            {watchDetail?.watch_category_id?.name}
          </span>
          <span
            className={` text-default opacity-60 text-white text-[10px] sm:text-xs`}
          >
            With {watchDetail?.bracelet_type_id?.name}
          </span>
        </div>
        <div className={`flex flex-row justify-center items-center gap-2 `}>
          <div className={`flex justify-end items-end text-default gap-1`}>
            <span
              className={`text-[10px] text-end flex items-end opacity-60 text-white leading-4 sm:leading-[5px] `}
            >
              {watchDetail?.product_currency?.code}
            </span>
            <span className={`text-xs sm:text-[24px] text-white font-normal`}>
              {watchDetail?.price}
            </span>
          </div>
          {/* {watchDetail?.has_product_discount && ( */}
          <Chip
            className="text-[10px]"
            text={"Special Price"}
            isBorder
            textColor={"gold"}
          />
          {/* )} */}
        </div>
        <div className={`flex justify-start flex-wrap items-center gap-2`}>
          {watchDetail?.tags?.map((value, index) => {
            return (
              <Chip key={index} text={value} textColor={"whiteSecondary"} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
