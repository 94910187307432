import React from 'react'
import { crown, arrowRight } from '../assets/icons'

function SellerCard({name, seller_type, rating, rating_from, onPress, seller_avtar}) {
    return (
        <div className='flex flex-row justify-start items-center p-4 cursor-pointer w-auto '
            style={{
                background: 'linear-gradient(136deg, #201D1D 50.15%, #181515 100%))',
                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
            }}
            onClick={() => onPress()}
        >
            <div className='flex flex-row justify-center items-center basis-3/4 gap-4'>
                <div className='flex flex-row justify-center items-center basis-1/4'>
                    <img src={seller_avtar} className='w-14 h-14 border-2 border-gold rounded-full' />
                </div>
                <div className='flex flex-col gap-1 basis-3/4 justify-center items-start'>
                    <span className='text text-white'>{name}</span>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <div className='flex flex-col justify-center items-center p-1'><img src={crown} className='w-4 h-4' /></div>
                        <p className='text text-xs'><span className={`text-gold mr-2`}>{seller_type}</span><span className='text-white'></span></p>
                    </div>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <span className='text text-gold text-xs'>Rating</span>
                        <span className='text text-gold text-xs'>{rating}/{rating_from}</span>
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-center items-center gap-2basis-1/4'>
                <span className='text text-gold text-sm'>Profile</span>
                <img src={arrowRight} className='w-6 h-6' />
            </div>
        </div>
    )
}

export default SellerCard