import React from 'react';

const WhyUsCard = ({ imageUrl, title, description }) => {
  return (
    <div className={`flex flex-col justify-center items-center text-center pb-0 sm:pb-[50px] w-[304px] h-auto`}>
      <div className={`mb-8`}>
        <img src={imageUrl} alt={title} />
      </div>
      <span className={`title-medium font-normal text-haeder din-text sm:text-4xl mb-2 sm:mb-5`}>{title}</span>
      <span className="text-tiny text-haeder">{description}</span>
    </div>
  );
};

export default WhyUsCard;

