import React, { useEffect, useState } from "react";
import RightArrow from "../assets/icons/Arrow-right.svg";
import LocationIcon from "../assets/icons/location_arrow.svg";
import LogoutIcon from "../assets/icons/my-account/logout.svg";
import OrderIcon from "../assets/icons/my-account/order.svg";
import UserIcon from "../assets/icons/my-account/user.svg";
import MyAddress from "../components/MyAddress";
import MyProfile from "../components/MyProfile";
import { persistor } from "../store/index";
import OrderHistory from "./OrderHistory";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser } from "../store/user/userThunk";

function MyAccount() {
  const dispatch = useDispatch();

  const Navigate = useNavigate();

  const userDetail = useSelector((state) => state.user.userDetail);

  console.log("userDetail", userDetail);

  const [selectedComponent, setSelectedComponent] = useState("A");

  const logout = async () => {
    try {
      const response = await dispatch(logoutUser({})).unwrap();
      // You can now check the response here
      console.log("response",response)
      if (!response.code) {
        Navigate("/");
      }
    } catch (err) {
      console.error("Failed to log in: ", err);
    }
    Navigate("/signin");
  };

  // Function to handle component selection
  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };
  const handleprofile = () => {
    Navigate("/profile");
  };
  const handleOrdersHistory = () => {
    Navigate("/orders");
  };
  const handleMyAddress = () => {
    Navigate("/my-address");
  };
  const renderComponent = () => {
    switch (selectedComponent) {
      case "A":
        return <MyProfile />;
      case "B":
        return <MyAddress />;
      case "C":
        return <OrderHistory />;
      default:
        return null;
    }
  };
  return (
    <div className={`bg-background800  sm:w-full h-screen`}>
      <div className="bg-background800 h-screen flex flex-col sm:flex-row">
        <div className="flex flex-col basis-auto sm:basis-1/4 p-0 sm:pl-9">
          <div className="flex flex-col justify-start gap-2 pl-6 sm:p-0 ">
            <span className="title text-2xl text-white cursor-pointer mt-8 sm:mt-2">
              My Account
            </span>

            {!userDetail?.name ? (
              <div className="flex flex-row gap-[6px]  items-center">
                <img src={LogoutIcon} className="h-6 w-6" alt="logout" />
                <button
                  className="text text-sm text-gold cursor-pointer"
                  onClick={() => Navigate("/signin")}
                >
                  Sign In
                </button>{" "}
                <span className="text-gold">|</span>
                <button
                  className="text text-sm text-gold cursor-pointer"
                  onClick={() => Navigate("/registration_1")}
                >
                  Sign Up
                </button>
              </div>
            ) : (
              <>
                <div className="flex flex-row gap-[6px] items-center p-4">
                  <img src={UserIcon} className="h-6 w-6" alt="user" />
                  <span className="text text-white cursor-pointer">
                    {userDetail?.name}
                  </span>
                </div>
                <div className="flex flex-row gap-[6px]  items-center p-4">
                  <img src={LogoutIcon} className="h-6 w-6" alt="logout" />
                  <button
                    className="text text-sm text-gold cursor-pointer"
                    onClick={logout}
                  >
                    Log out
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="hidden sm:flex flex-col gap-4 mt-[42px] ">
            <div
              onClick={() => handleComponentChange("A")}
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
            >
              <div className="flex flex-row items-center justify-center">
                <img src={UserIcon} className="h-6 w-6" alt="profile" />
                <span className="text text-sm text-white">Profile</span>
              </div>
              <img src={RightArrow} alt="icon" className="ml-2" />
            </div>
            <div
              onClick={() => handleComponentChange("B")}
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
            >
              <div className="flex flex-row items-center justify-center">
                <img src={LocationIcon} className="h-6 w-6" alt="address" />
                <span className="text text-sm text-white">My Address</span>
              </div>
              <img src={RightArrow} alt="rightIcon" className="ml-2" />
            </div>
            <div
              onClick={() => handleComponentChange("C")}
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
            >
              <div className="flex flex-row items-center justify-center">
                <img src={OrderIcon} className="h-6 w-6" alt="history" />
                <span className="text text-sm text-white">Order History</span>
              </div>
              <img src={RightArrow} alt="icon" className="ml-2" />
            </div>
          </div>
          <div className="flex sm:hidden flex-col gap-4 mt-[42px] px-4">
            <div
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
              onClick={() => handleprofile()}
            >
              <div className="flex flex-row items-center justify-center">
                <img src={UserIcon} className="h-6 w-6" alt="profile" />
                <span className="text text-sm text-white">Profile</span>
              </div>
              <img src={RightArrow} alt="icon" className="ml-2" />
            </div>
            <div
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
              onClick={() => handleMyAddress()}
            >
              <div className="flex flex-row items-center justify-center">
                <img src={LocationIcon} className="h-6 w-6" alt="address" />
                <span
                  className="text text-sm text-white"
                  onClick={() => handleComponentChange("B")}
                >
                  My Address
                </span>
              </div>
              <img src={RightArrow} alt="icon" className="ml-2" />
            </div>
            {/* To be done */}
            <div
              className="flex flex-row gap-[6px] justify-between items-center bg-backgroundButton rounded-lg p-4 cursor-pointer"
              onClick={() => handleOrdersHistory()}
            >
              <div className="flex flex-row items-center">
                <img src={OrderIcon} className="h-6 w-6" alt="history" />
                <span
                  className="text text-sm text-white"
                  onClick={() => handleComponentChange("C")}
                >
                  Order History
                </span>
              </div>
              <img src={RightArrow} alt="icon" className="ml-2" />
            </div>
          </div>
        </div>
        <div className="hidden sm:flex flex-col basis-3/4">
          {renderComponent()}
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
