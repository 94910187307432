import React from 'react'

const CountryChip = ({text, isBorder, textColor, countryLogo}) => {
  return (
    <div className={`flex justify-center items-center rounded-full py-1 ${countryLogo ? 'pr-[10px] pl-1 gap-1' : 'p-2'} cursor-pointer bg-background900 bg-opacity-25 `}>
    <img src={countryLogo}></img>
        {/* <span className={`text-default text-[10px]  text-${textColor}`}>{text}</span> */}
        <span className={`text-default text-[10px] font-normal text-${textColor}`}>
  {text.split(" ").length > 1 ? `${text.split(" ")[0]}-` : text}
</span>

    </div>
  )
}

export default CountryChip