import React from 'react'

const Chip = ({text, isBorder, textColor, countryLogo}) => {
  return (
    <div className={`flex justify-center items-center rounded-full py-1 px-[10px] cursor-pointer ${isBorder ? 'border border-gold border-solid  bg-transparent' : 'bg-background900'} `}>
    <img src={countryLogo}></img>
        <span className={`text-default text-[10px] font-normal text-${textColor}`}>{text}</span>
    </div>
  )
}

export default Chip